var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classes,
      attrs: { index: _vm.index, draggable: true },
      on: {
        dragover: _vm.onDragOver,
        dragstart: _vm.onDragStart,
        dragend: _vm.onDragEnd
      }
    },
    [
      _c("IconButton", {
        ref: "dragButton",
        staticClass: "draggable-form-item__drag-button",
        attrs: { size: _vm.smallSize, theme: _vm.secondaryPlainTheme },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function() {
              return [_c("IconDragDrop")]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isGhostVisible,
              expression: "!isGhostVisible"
            }
          ],
          staticClass: "draggable-form-item__body"
        },
        [_vm._t("item")],
        2
      ),
      _vm._v(" "),
      _c("DraggableFormGhostItem", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isGhostVisible,
            expression: "isGhostVisible"
          }
        ]
      }),
      _vm._v(" "),
      _c("IconButton", {
        staticClass: "draggable-form-item__remove-button",
        attrs: { size: _vm.smallSize, theme: _vm.secondaryPlainTheme },
        nativeOn: {
          click: function($event) {
            return _vm.onRemove.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function() {
              return [_c("IconFilledClose")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }