var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "draggable-form-placeholder-item" },
    [_c("IconDragDrop"), _vm._v("\n    Drag & drop the item here\n")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }