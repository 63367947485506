var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "draggable-form",
      on: {
        drop: _vm.onDrop,
        dragenter: _vm.onDragEnter,
        dragover: _vm.onDragOver
      }
    },
    [
      _c("Form", {
        attrs: {
          title: _vm.title,
          disabled: _vm.disabled,
          width: _vm.width,
          errors: _vm.errors,
          "change-values": _vm.changeValues,
          "errors-presentation-mapper": _vm.errorsPresentationMapper
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm.title
                  ? _c("h2", {
                      staticClass: "draggable-form__title",
                      domProps: { textContent: _vm._s(_vm.title) }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _vm.isPlaceholderItemVisible
                  ? _c("DraggableFormPlaceholderItem")
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.localItems, function(item, index) {
                  return _c("DraggableFormItem", {
                    key:
                      (item.id || item.fieldKey) +
                      "|" +
                      (item.languageCode || ""),
                    attrs: { scope: _vm.scope, index: index, item: item },
                    on: {
                      "remove-item": _vm.onRemoveItem,
                      swap: _vm.onSwapItems,
                      "drag-end": _vm.onDragEndItem
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function() {
                            return [_vm._t("item", null, { item: item })]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }